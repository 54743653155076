import fetch from 'auth/FetchInterceptor'

const ProfileService = {}

ProfileService.getProfile = function () {
    return fetch({
        url: '/profile',
        method: 'get'
    })
}

ProfileService.updateProfile = function (data) {
    return fetch({
        url: '/update-profile',
        method: 'post',
        data: data
    })
}



export default ProfileService;