import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'reset-password',
        path: `reset-password`,
        component: React.lazy(() =>import('views/auth-views/authentication/reset-password')),
    },
    {
        key: 'impersonate',
        path: `${AUTH_PREFIX_PATH}/impersonate`,
        component: React.lazy(() =>import('views/auth-views/authentication/impersonate')),
    }
    
]

export const protectedRoutes = [
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/dashboard`,
        component: React.lazy(() => import('views/app-views/dashboard')),
    },
    {
        key: 'profile',
        path: `${APP_PREFIX_PATH}/profile`,
        component: React.lazy(() => import('views/app-views/profile')),
    },
    {
        key: 'password',
        path: `${APP_PREFIX_PATH}/change-password`,
        component: React.lazy(() => import('views/app-views/password')),
    },
    {
        key: 'privacy',
        path: `${APP_PREFIX_PATH}/privacy`,
        component: React.lazy(() => import('views/app-views/privacy')),
    },
    {
        key:'selection',
        path:`${APP_PREFIX_PATH}/translator/selection`,
        component: React.lazy(() => import('views/app-views/translator/selection')),
    },{
        key:'transcription',
        path:`${APP_PREFIX_PATH}/translator/transcription`,
        component: React.lazy(() => import('views/app-views/translator/transcription')),
    
    },
    {
        key:'list',
        path:`${APP_PREFIX_PATH}/translator`,
        component: React.lazy(() => import('views/app-views/translator/list')),
    },
    {
        key:'bonus',
        path:`${APP_PREFIX_PATH}/bonus`,
        component: React.lazy(() => import('views/app-views/bonus')),
    },
    {
        key:'tutorials',
        path:`${APP_PREFIX_PATH}/tutorials`,
        component: React.lazy(() => import('views/app-views/tutorials')),
    },
    {
        key:'magiccut',
        path:`${APP_PREFIX_PATH}/magiccut`,
        component: React.lazy(() => import('views/app-views/shortz')),
    },
    {
        key:'magiccutcreate',
        path:`${APP_PREFIX_PATH}/magiccut/create`,
        component: React.lazy(() => import('views/app-views/shortz/CreateShortz')),
    },
    {
        key:'agency',
        path:`${APP_PREFIX_PATH}/agency`,
        component: React.lazy(() => import('views/app-views/agency')),
    },
    {
        key:'teams',
        path:`${APP_PREFIX_PATH}/teams`,
        component: React.lazy(() => import('views/app-views/teams')),
    }
]